import { setter } from "elum-state/react";
import { PAGE } from "../states";
import React from "react";
import { useLocation } from "react-router-dom";
import {SearchPage} from "../components/pages/Search";

export const Search: React.FC = () => {
    const location = useLocation();

    const getQueryParam = (param: string): string | null => {
        const searchParams = new URLSearchParams(location.search);
        return searchParams.get(param);
    };

    React.useEffect(() => {
        const searchQuery = getQueryParam('q');


        setter(PAGE, {
            title: 'Поиск',
            link: `/search?q=${encodeURIComponent(searchQuery || '')}`,
        });
    }, [location.search]);

    return (
        <SearchPage q={String(getQueryParam('q'))} />
    );
};
