import {TSearchResult} from "./index";
import {FC} from "react";
import styles from "./Search.module.scss";
import {Tools} from "../../../assets/tools";

export const Row: FC<{data: TSearchResult}> = ({data}) => {

    const openProduct = () => {
        window.open(`/product/${data.id}`, '_blank')

    }

    return (
       <div className={styles.row} onClick={openProduct}>
           <div className={styles.row_photo}>
               <img src={data.photo}/>
           </div>
           <div className={styles.row_info}>
               <p>{data.title}</p>
               <p>{Tools.Spacer(data.price)} руб.</p>
           </div>
       </div>
    )
}