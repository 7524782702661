import { Box, TextField, Typography } from "@mui/material";
//@ts-ignore
import { ReactComponent as Logo } from '../../assets/logo.svg';
import { useNavigate, useLocation } from "react-router-dom";
import SearchIcon from '@mui/icons-material/Search';
import React from "react";
import { IS_MOBILE } from "../../config";
import { GraphQL } from "../../api/graphql/queres/GraphQL.queres";
import { client } from "../../api/graphql/client/client";
import {getter, setter} from "elum-state/react";
import { PAGE } from "../../states";

export const Cap: React.FC = () => {
    const [search, setSearch] = React.useState<string>('');
    const navigate = useNavigate();
    const location = useLocation();
    const page = getter(PAGE)

    React.useEffect(() => {
        // Получаем значение параметра 'q' из URL
        const query = new URLSearchParams(location.search).get('q');
        if (query) {
            setSearch(query); // Устанавливаем значение в состояние
        }
    }, [location.search]);

    const formSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const url = `/search?q=${encodeURIComponent(search)}`;
        if (!page.link.includes('search')) {
            window.open(url, '_blank');
        } else {
            window.location.href = url
        }
    };

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            gap: !IS_MOBILE ? 0 : 2,
            overflow: 'visible',
            flexDirection: !IS_MOBILE ? 'row' : 'column'
        }}>
            <Box sx={{ cursor: 'pointer' }} onClick={() => navigate('/')}>
                <Logo />
            </Box>
            <Box display='flex' flexDirection='column' alignItems='center'>
                <Typography>
                    M A K O .
                </Typography>
                <a href={'tel:+79206726456'} style={{ textDecoration: 'none' }}>
                    +7 910 672 64 56
                </a>
            </Box>
            {!IS_MOBILE &&
                <Box overflow={'visible'} width='40%' height={50}>
                    <form onSubmit={formSubmit} style={{ overflow: 'visible' }}>
                        <TextField
                            id={'search'}
                            sx={{
                                overflow: 'visible',
                                width: '100%',
                                "& *, &": {
                                    color: '#fff !important',
                                    borderColor: '#fff !important',
                                    borderRadius: '10px !important'
                                },
                                "& > .MuiInputBase-input:hover, &:hover": {
                                    borderColor: '#fff !important'
                                }
                            }}
                            InputProps={{
                                endAdornment: (
                                    <SearchIcon sx={{ cursor: 'pointer', overflow: 'visible' }} onClick={formSubmit} />
                                ),
                            }}
                            value={search}
                            onChange={({ target: { value } }) => setSearch(value)} // Обновляем состояние при изменении
                            label="Поиск"
                            variant="outlined"
                        />
                    </form>
                </Box>}
            {!IS_MOBILE &&
                <Box>
                    <Typography textAlign={'center'} dangerouslySetInnerHTML={{ __html: '15 лет<br>на рынке!' }} />
                </Box>
            }
        </Box>
    );
};
