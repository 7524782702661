import {Box, Typography} from "@mui/material";
import {setter, useGlobalValue} from "elum-state/react";
import {PAGE} from "../../states";
import {navigation} from "./navbar.mock";
import {useNavigate} from "react-router-dom";
import {Cap} from "./Cap";
import {IS_MOBILE} from "../../config";
import React from "react";



export const  HeaderPanel = () => {
    const page = useGlobalValue(PAGE);
    const navigate = useNavigate();
    const top = '50%';
    const [mobileHeight, setMobileHeight] = React.useState(0);

    const nextPage = (el: typeof navigation[0]) => {
        setter(PAGE, {
            title: el.title,
            link: el.link
        });


        window.open(window.location.origin + '/' +el.link, '_blank');
    }

    const openMenu = () => {
        if (!mobileHeight) {
            setMobileHeight(250)
        } else  {
            setMobileHeight(0)
        }
    }
    return (

        <Box display='flex' flexDirection='column' width='100%' alignItems={'center'} sx={{marginBottom: IS_MOBILE ? '20px' : ''}}>
            <Box className='center'  width='100%' minHeight={100} sx={{background: 'rgba(0,0,0,0.62)', paddingTop: '10px'}}>
                <Cap/>
            </Box>
            <Box className='center'  width='100%' sx={{
                background: 'rgba(0,0,0,0.84)',
                borderRadius: !IS_MOBILE ? '0 0 20px 20px' : '0',
                display: 'flex',
                flexDirection: !IS_MOBILE ? 'row' : 'column',
                alignItems: 'center',
                height: !IS_MOBILE ? 50 : mobileHeight,
                transition: 'height .3s',
                justifyContent: 'space-between'}}>
                {
                    navigation.map((el) =>
                        <Typography onClick={() => nextPage(el)} sx={{
                            cursor: 'pointer',
                            transition:' all 0.3s',
                            backgroundImage: 'linear-gradient(currentColor, currentColor)',
                            backgroundPosition: '0% 100%',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: '0% 1px',
                            fontWeight: 300,
                            "&:hover": {
                                transform: 'translateY(-3px)',
                                backgroundSize: '100% 1px',
                            }
                        }} key={`${el.id}_nav_titles`}>
                            <a href={el.link} style={{textDecoration: 'none'}}>
                            {el.title}
                            </a>
                        </Typography>)
                }
            </Box>
            {!IS_MOBILE ?
                <Box sx={{padding: '0 20px', display: 'flex', alignItems: 'center', justifyContent: 'center',minWidth: '100px', height: 50, background: 'rgba(0,0,0,0.84)',borderRadius: '0 0 20px 20px'}}>
                    <Typography width='100%' textAlign='center'>
                        {page.title}
                    </Typography>
                </Box> :
                <Box width='100%' height={50} sx={{background: 'rgba(0,0,0,0.62)', borderRadius: '0 0 10px 10px',display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'visible'}}>
                    <Box onClick={openMenu} sx={{position: 'relative',height: '15px',  width: 30, overflow: 'visible', cursor: 'pointer'}}>
                        <Box sx={{transition: 'all .3s', width: '100%', height: '3px', borderRadius: '3px', backgroundColor: '#fff', position: 'absolute', top: `${mobileHeight ? top : '0%'}`, left: 0, transform: 'translateY(-50%)'}}/>
                        <Box sx={{transition: 'all .3s',width: '100%', height: '3px', borderRadius: '3px', backgroundColor: '#fff', position: 'absolute', top: top, left: 0, transform: 'translateY(-50%)'}}/>
                        <Box sx={{transition: 'all .3s',width: '100%', height: '3px', borderRadius: '3px', backgroundColor: '#fff', position: 'absolute', top: `${mobileHeight ? top : '100%'}`, left: 0, transform: 'translateY(-50%)'}}/>
                    </Box>
                </Box>
            }

        </Box>
    )
}
