import React, {FC} from "react";
import {client} from "../../../api/graphql/client/client";
import {GraphQL} from "../../../api/graphql/queres/GraphQL.queres";
import {CircularProgress} from "@mui/material";
import styles from './Search.module.scss'
import clsx from "clsx";
import {Row} from "./Row";

type TParams = {
    q: string
}

export type TSearchResult = {
    id: string
    title: string
    photo: string
    price: number
}

export const SearchPage: FC<TParams> = ({q}) => {
    const [searchResult, setSearchResult] = React.useState<TSearchResult[] | []>([])
    const [loading, setLoading] = React.useState(true)


    const getSearchResults = async () => {
        try {
            const {data} = await client.query({query: GraphQL.search(q)})
            const gqlParsedData = data.products.edges.map((el: any) => ({
                title: el.node.title,
                id: el.node.id,
                photo: el.node.productPhoto?.photo?.node?.mediaDetails?.sizes[0]?.sourceUrl,
                price: el.node.productsSchema?.price,
            }))
            setSearchResult(gqlParsedData)
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }
    }

    React.useEffect(() => {
        getSearchResults()
    }, [])


    return (
        <div className={clsx([styles.search, 'center'])}>
            {
                loading ? <CircularProgress /> :
                    <div className={styles.search_content}>
                        <div className={styles.search_head}>
                            <h1>По запросу "{q}" найдено {searchResult.length} совпадений</h1>
                        </div>
                        <div className={styles.search_rows}>
                            {
                                searchResult.map((el) => <Row key={`${el.id}_search`} data={el} />)
                            }
                        </div>
                    </div>
            }

        </div>
    )
}