
export class Tools {
    static Spacer(x: number) {
        if (x <= 0) return  '0'
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }


    static NumberFormatter(num: number): string {
        const units = ['К', 'М', 'В','Т', 'QD', 'QT', 'ST', 'SP','O', 'N', 'D', 'U', 'DUO'];
        const base = Math.floor(Math.log10(Math.abs(num)) / 3);

        if (base <= 0) {
            return num.toFixed(0).toString();
        }

        const unitIndex = Math.min(base - 1, units.length - 1);
        const formattedNum = num / Math.pow(10, base * 3);

        return formattedNum.toFixed(2) + units[unitIndex];
    }
}
